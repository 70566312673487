import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Portrait from 'common/src/assets/image/okcharlo-favicon-red.jpg';
import Row from '../../../components/Row';
import Container from 'common/src/components/UI/Container';
import { Auth } from 'aws-amplify';
import { fontSize } from 'styled-system';
import { OutboundLink } from "gatsby-plugin-google-analytics"

import BlockWrapper, {
  CardElementContainer,
  SubmitButton,
  TextContent,
  TextContainer,
} from './welcome.style';

const snowTongue = {
  src:Portrait,
  pre:Portrait,
};

const PricingSection = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  secFooter,
  secText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      charityJson {
        welcome {
          title
          subtitle
          slogan
          description
          buttonText
          buttonUrl
        }
      }
    }
  `);

  const data = Data.charityJson.welcome;

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <BlockWrapper>
        <Container>
          <Box {...secTitleWrapper}>
            <header>
              <Heading
                {...secHeading}
                as="h5"
                {...secText}
                content={data.slogan}
              />
              <Heading as="h2" {...secHeading} content={data.title} />
              <Heading as="h3" {...secHeading} content={data.subtitle} />
            </header>
          </Box>

          <Box {...secFooter}>
            <img
              className="roundedPic"
              alt={''}
              src={''}
              srcSet={`${snowTongue.src} 100w`}
              sizes={`(min-width: 100px) 80vw`}
            />

            <Heading
              {...secFooter}
              as="h4"
              className="descriptionStyle"
              content={data.description}
            />

            <Link to={data.buttonUrl}>
              <SubmitButton
                {...secFooter}
                title="Signout"
                onClick={() => {
                  Auth.signOut();
                }}
                className="active"
              >
                {data.buttonText}
              </SubmitButton>
            </Link>
          </Box>
        </Container>
      </BlockWrapper>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secFooter: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['2rem'],
    pb: '20px',
  },
  secTitleWrapper: {
    mb: ['30px', '40px'],
  },
  secText: {
    as: 'h5',
    display: 'block',
    textAlign: 'center',
    letterSpacing: '0.15em',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    width: '100%',
    maxWidth: '500px',
  },
  secFooter: {
    textAlign: 'center',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    width: '100%',
    maxWidth: '700px',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    color: '#0f2137',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: '#0f2137',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: '#6e7379',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',

    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // bg: '#fff',
    // color: '#2aa275',
    // colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '15px',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    // colors: 'primaryWithBg',
    minWidth: ['160px', '190px'],
    maxWidth: '100%',
    height: '48px',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#6e7379',
    mb: '0',
  },
};

export default PricingSection;
